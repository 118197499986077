import React from 'react';
import { useParams } from 'react-router-dom';
import { PHOTO_GET } from '../../api';
import useFetch from '../../Hooks/useFetch';
import Error from '../../Components/Helper/Error';
import Loading from '../../Components/Helper/Loading';
import PhotoContent from '../../Components/Photo/PhotoContent';
import Head from '../Helper/Head';

const Photo = () => {
  const { id } = useParams();
  const { data, loading, error, request } = useFetch();

  React.useEffect(() => {
    const { url, options } = PHOTO_GET(id);
    request(url, options);
  }, []);

  if (error) return <Error error={error} />;
  if (loading) return <Loading />;
  return (
    <section className="container mainContainer">
      {data && <Head title={data.photo.title} />}
      {data && <PhotoContent data={data} single={true} />}
    </section>
  );
};

export default Photo;
