import React from 'react';
import Image from '../Helper/Image';
import styles from './FeedPhotosItem.module.css';

const FeedPhotosItem = ({ photo, setModalPhoto }) => {
  console.log(photo);
  if (photo)
    return (
      <li
        className={`${styles.photo} animeLeft`}
        onClick={() => setModalPhoto(photo)}
      >
        <Image alt={photo.title} src={photo.src} />
        <span className={styles.views}>{photo.acessos}</span>
      </li>
    );
  else return null;
};

export default FeedPhotosItem;
