import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { UserContext } from '../../UserContext';
import Feed from '../Feed/Feed';
import Head from '../Helper/Head';
import NotFound from '../NotFound';
import UserHeader from './UserHeader';
import UserPhotoPost from './UserPhotoPost';
import UserStats from './UserStats';

const User = () => {
  const [title, setTitle] = React.useState('');
  const { data } = React.useContext(UserContext);
  React.useEffect(() => setTitle('Início'), []);
  return (
    <section className="container">
      <Head title="Minha conta" />
      <UserHeader title={title} />
      <Routes>
        <Route path="/" element={<Feed user={data.id} />} />
        <Route path="postar" element={<UserPhotoPost setTitle={setTitle} />} />
        <Route
          path="estatisticas"
          element={<UserStats setTitle={setTitle} />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </section>
  );
};

export default User;
