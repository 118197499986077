import React from 'react';

const NotFound = () => {
  return (
    <div className="contaniner mainContainer">
      <h1 className="title">Error: 404</h1>
      <p>Página não encontrada</p>
    </div>
  );
};

export default NotFound;
